@import "~antd/dist/antd.css";
@import "../constants/style.scss";
.buttonanimation {
  position: relative;
  animation: mymove 1.5s;
  animation-iteration-count: infinite;
}
.color-button-primary {
  background-color: #318dcf;
  border-color: #318dcf;
}
ol {
  list-style: none;
}
body:not(.ace_editor) {
  font-family: "Prompt", sans-serif;
}
.is-blue-bg {
  background-color: #318dcf;
}
.is-darkblue-bg {
  background-color: #3180cf;
}
.is-lightblue-bg {
  background-color: #f2f9fc;
}
.is-white-bg {
  background-color: #fff;
}
.is-transparent {
  background-color: transparent;
}
.is-noborder {
  border: none;
}
.is-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.is-page-wrapper {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  z-index: 1;
}

.is-page {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0 24px;
}
.is-text-align-center {
  text-align: center;
}
.is-text-align-left {
  text-align: left;
}
.is-text-align-right {
  text-align: right;
}
.is-shadow-card {
  box-shadow: rgba(0, 0, 0, 0.0980392) 0px 2px 20px 0px;
}
.is-footer {
  height: 64px;
  p {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
    color: #fff;
  }
}
.ant-drawer {
  overflow-y: scroll;
}
.is-header {
  height: 64px;
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item > a {
    color: #fff;
  }
  .ant-menu {
    color: #fff;
    font-size: 16px;
  }
  p {
    color: #fff;
  }
}
.is-drawer-header {
  .ant-drawer-body {
    padding: 0 24px 10px 24px;
  }
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-menu-item-group-title {
    font-size: 16px;
    padding-left: 0px;
  }
  .anticon {
    padding-right: 4px;
  }
  .anticon-close {
    padding-right: 0px;
  }
  .submenu-title-wrapper {
    color: #05366c;
  }
  .ant-menu-item {
    font-size: 16px;
  }
}
.banner {
  margin-top: 64px;
  .text-wrapper {
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h2 {
      font-weight: 600;
      font-size: 50px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h4 {
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    p {
      font-size: 20px;
      line-height: 35px;
      color: #fff;
    }
  }
  .is-fullpage {
    height: calc(100vh - 128px);
    margin-bottom: 64px;
  }
  .is-fullpage-tablet {
    min-height: calc(60vw - 128px);
    margin-bottom: 64px;
  }
}
.about {
  margin-top: 64px;
  .text-wrapper {
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #05366c;
    }
    h2 {
      font-weight: 600;
      font-size: 50px;
      margin-bottom: 0.05em;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    p {
      font-size: 16px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100% - 128px);
    margin-bottom: 64px;
    min-height: 300px;
  }
}
.whyus {
  margin-top: 64px;
  .card {
    padding: 8px;
    .inner-card {
      border-top: #018ddd 8px solid;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      padding: 8px;
      background-color: #fff;
    }
  }
  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      color: #05366c;
    }
    p {
      font-size: 16px;
      line-height: 35px;
      color: #4a4a4a;
    }
    .description {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
      min-height: 210px;
    }
  }
  .img-wrapper {
    text-align: center;
  }
  .is-fullpage {
    height: calc(100% - 128px);
    margin-bottom: 64px;
    min-height: 300px;
  }
  .is-right-border {
    border-right: rgba(5, 54, 108, 0.1) 1px solid;
  }
}
.feature {
  margin-top: 64px;
  .card {
    padding: 16px;
  }
  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      color: #05366c;
    }
    p {
      font-size: 16px;
      line-height: 35px;
      color: #4a4a4a;
    }
    .description {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
      min-height: 210px;
    }
    .no-min-height {
      min-height: unset;
    }
  }
  .img-wrapper {
    text-align: center;
  }
  .is-fullpage {
    height: calc(100% - 128px);
    margin-bottom: 64px;
    min-height: 300px;
    height: 100%;
  }
  .is-right-border {
    border-right: rgba(5, 54, 108, 0.1) 1px solid;
  }
  .is-bottom-border {
    border-bottom: rgba(5, 54, 108, 0.1) 1px solid;
  }
}
.package {
  margin-top: 64px;
  table {
    border-radius: 6px;
    background-color: #fff;
  }
  .ant-table-body {
    p {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 0;
    }
  }

  .topic {
    font-size: 14px;
    line-height: 14px;
  }
  // .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr:first-child > td:first-child {
  //   background: #f2f9fc;
  // }
  // .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr:first-child > td:last-child {
  //   background: #f2f9fc;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(1)
  //   > td:nth-child(2) {
  //   border-top-left-radius: 6px;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(2)
  //   > td:nth-child(2) {
  //   background: #d7f2fc;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(3)
  //   > td:nth-child(2) {
  //   background: #c5ecfb;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(4)
  //   > td:nth-child(2) {
  //   background: #b3e6f9;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(5)
  //   > td:nth-child(2) {
  //   background: #a0e0f8;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(6)
  //   > td:nth-child(2) {
  //   background: #8edaf7;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(7)
  //   > td:nth-child(2) {
  //   background: #7cd4f5;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(2)
  //   > td:nth-child(3) {
  //   background: #d5eefd;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(3)
  //   > td:nth-child(3) {
  //   background: #c2e6fc;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(4)
  //   > td:nth-child(3) {
  //   background: #b0dffb;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(5)
  //   > td:nth-child(3) {
  //   background: #9dd7fa;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(6)
  //   > td:nth-child(3) {
  //   background: #8ad0f9;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(7)
  //   > td:nth-child(3) {
  //   background: #77c8f9;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(2)
  //   > td:nth-child(4) {
  //   background: #e1e8f4;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(3)
  //   > td:nth-child(4) {
  //   background: #d3dcef;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(4)
  //   > td:nth-child(4) {
  //   background: #c5d1e9;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(5)
  //   > td:nth-child(4) {
  //   background: #b7c6e4;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(6)
  //   > td:nth-child(4) {
  //   background: #a8bade;
  // }
  // .ant-table-small
  //   > .ant-table-content
  //   > .ant-table-body
  //   > table
  //   > .ant-table-tbody
  //   > tr:nth-child(7)
  //   > td:nth-child(4) {
  //   background: #9aafd9;
  // }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(2)
    > td:nth-child(2) {
    background: #d7f2fc;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(4)
    > td:nth-child(2) {
    background: #d7f2fc;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(6)
    > td:nth-child(2) {
    background: #d7f2fc;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(2)
    > td:nth-child(3) {
    background: #d5eefd;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(4)
    > td:nth-child(3) {
    background: #d5eefd;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(6)
    > td:nth-child(3) {
    background: #d5eefd;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(2)
    > td:nth-child(4) {
    background: #e1e8f4;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(4)
    > td:nth-child(4) {
    background: #e1e8f4;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr:nth-child(6)
    > td:nth-child(4) {
    background: #e1e8f4;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset; //Change the existing color to `unset`
  }
  .ant-table-small.ant-table-bordered .ant-table-content {
    border: unset;
  }
  .ant-table-small {
    border: unset;
    border-radius: 4px;
  }
  .ant-table-thead > tr:nth-child(2) > th:nth-child(1) {
    display: none;
  }
  .ant-table-thead > tr:nth-child(2) > th:nth-child(2) {
    display: none;
  }
  h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #05366c;
    margin-bottom: 0;
  }
  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h2 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 16px;
      line-height: 35px;
      color: #4a4a4a;
    }
    .description {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
    .unitprice {
      font-size: 18px;
      line-height: 25px;
      color: #4a4a4a;
      // height: 210px;
    }
    .price {
      font-weight: 600;
      font-size: 30px;
      line-height: 35px;
      color: #05366c;
    }
    .is-blue-detail {
      font-size: 15px;
      line-height: 35px;
      color: #4a4a4a;
      background-color: #d9f2fc;
      margin-left: -8px;
      margin-right: -8px;
    }
    .is-white-detail {
      font-size: 15px;
      line-height: 35px;
      color: #4a4a4a;
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  .img-wrapper {
    text-align: center;
  }
  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 300px;
    margin-bottom: 64px;
  }
  .is-right-border {
    border-right: rgba(5, 54, 108, 0.1) 1px solid;
  }
  .card {
    padding: 8px;
    .inner-card {
      padding: 8px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}
.gosignup {
  margin-top: 64px;
  .card {
    padding: 8px;
  }
  .text-wrapper {
    text-align: center;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      color: #05366c;
    }
    p {
      font-size: 16px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .img-wrapper {
    text-align: center;
  }
  .is-fullpage {
    height: calc(100% - 128px);
    margin-bottom: 64px;
    min-height: 100px;
  }
  @keyframes mymove {
    from {
      top: 0px;
    }
    to {
      top: 10px;
    }
  }
}
.contact {
  margin-top: 24px;
  .card {
    padding: 8px;
    .inner-card {
      padding: 8px;
    }
  }
  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #fff;
    }

    p {
      font-size: 14px;
      line-height: 35px;
      color: #fff;
      word-wrap: break-word;
    }
  }

  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 100px;
    margin-bottom: 20px;
  }
}
.description {
  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 100px;
    margin-bottom: 64px;
  }
}
.notfound {
  margin-top: 64px;

  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100vh - 323px);
    min-height: 552px;
  }
}
.termsofuse {
  margin-top: 96px;

  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 100px;
    margin-bottom: 64px;
  }
}
.privacypolicy {
  margin-top: 96px;

  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 100px;
    margin-bottom: 64px;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: #fff;
  border-color: #fff;
}

.ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: #fff;
}

.redeemtermofuse {
  margin-top: 96px;

  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 100px;
    margin-bottom: 64px;
  }
}
.product {
  margin-top: 96px;
  .img-border {
    border: 1px solid #f0f0f0;
  }
  .text-wrapper {
    text-align: left;
    h1 {
      font-weight: 600;
      font-size: 60px;
      margin-bottom: 0.05em;
      color: #f8e71c;
    }
    h2 {
      font-weight: 600;
      font-size: 50px;
      margin-bottom: 0em;
      color: #05366c;
    }

    h3 {
      font-weight: 600;
      font-size: 40px;
      color: #05366c;
    }
    h3 {
      font-weight: 600;
      font-size: 30px;
      color: #05366c;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #05366c;
    }
    p {
      font-size: 14px;
      line-height: 35px;
      color: #4a4a4a;
    }
  }
  .is-fullpage {
    height: calc(100% - 128px);
    min-height: 100px;
    margin-bottom: 64px;
  }
}
.is-white-color {
  filter: brightness(0) invert(1);
}
